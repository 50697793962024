@import '../../functions';

.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  margin: toRem(20) 0 0;
  transition: transform 0.3s ease;

  @media (max-width: 991px) {
    margin: toRemMobile(12) 0 0;
  }

  &.hidden {
    margin: 0;
    transform: translate(0, -100%);
  }

  &.scrolled {
    margin: 0;
    backdrop-filter: blur(toRem(5));
  }

  &Body {
    display: flex;
    align-items: center;
    height: toRem(50);

    @media (max-width: 991px) {
      height: toRemMobile(21);
    }

    &__logo {
      cursor: pointer;
      width: toRem(91);
      // height: toRem(24);

      @media (max-width: 991px) {
        width: toRemMobile(65);
        // height: toRemMobile(21);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__links {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;

      &Item {
        cursor: pointer;
        color: #c4dbe0;
        font-family: Helvetica Now Display;
        font-size: toRem(18);
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: toRem(0.14);

        &:hover {
          text-decoration-line: underline;
        }

        &:not(:first-child) {
          margin: 0 0 0 toRem(40);
        }
      }
    }

    &__burger {
      width: toRemMobile(36);
      height: toRemMobile(16);
      position: relative;
      cursor: pointer;
      margin: 0 0 0 auto;

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: toRemMobile(1);
        background-color: #c4dbe0;

        &:nth-child(1) {
          top: toRemMobile(5);
        }
        &:nth-child(2) {
          bottom: toRemMobile(5);
        }
      }
    }

    &__menu {
      position: fixed;
      z-index: 10;
      background-color: #010001;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      padding: toRem(12) 0 toRem(40);

      transition: all 0.4s ease;

      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: unset;

        .headerBody__menuTitle {
          span {
            opacity: 1;
            transform: translate(0);
          }
        }
        .headerBody__menuBody {
          &::before {
            width: 100%;
          }
        }
        .headerBody__menuItem {
          .wrap {
            transform: translate(0);
          }
          .num {
          }
          &::after {
            width: 100%;
          }
        }
        .headerBody__menuCr {
          span {
            transform: translate(0);
          }
        }
      }

      &Top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &Title {
        position: relative;
        font-family: Helvetica Now Display;
        font-size: toRemMobile(28);
        font-style: normal;
        font-weight: 400;
        line-height: 94%;
        letter-spacing: toRemMobile(-1.5);
        color: transparent;
        overflow: hidden;

        span {
          position: absolute;
          color: #c4dbe0;
          opacity: 0;
          transform: translate(0, 100%);
          transition: all 0.4s ease;
        }
      }

      &Close {
        cursor: pointer;
        width: toRemMobile(36);
        height: toRemMobile(16);
        position: relative;

        span {
          position: absolute;
          display: block;
          width: 100%;
          height: toRemMobile(1);
          background-color: #c4dbe0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      &Body {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: toRemMobile(58) 0 0;
        padding: toRemMobile(50) 0 0;

        &::before {
          content: '';
          display: block;
          width: 0;
          height: toRemMobile(1);
          background: rgb(53, 58, 60);
          background: linear-gradient(
            90deg,
            rgba(53, 58, 60, 1) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          position: absolute;
          top: 0;
          transition: all 0.4s ease 0.1s;
        }
      }

      &Item {
        display: flex;
        flex: 1 1 100%;
        margin: 0 0 0 toRemMobile(68);
        padding: toRemMobile(8) 0;
        position: relative;

        color: transparent;
        font-family: Helvetica Now Display;
        font-size: toRemMobile(18);
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: toRemMobile(0.12);
        overflow: hidden;

        // @media (max-width: 340px) {
        //   margin: 0 0 0 40px;
        // }

        &::after {
          content: '';
          width: 0;
          height: toRemMobile(1);
          background: rgb(53, 58, 60);
          background: linear-gradient(
            90deg,
            rgba(53, 58, 60, 1) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          position: absolute;
          left: 0;
          bottom: 0;
          transition: all 0.5s ease;
        }

        .wrap {
          position: absolute;
          color: #c4dbe0;

          transform: translate(0, calc(100% + toRemMobile(8)));
          transition: all 0.4s ease;
        }

        .num {
          flex: 0 0 auto;
          margin: 0 toRemMobile(70) 0 0;

          // @media (max-width: 340px) {
          //   margin: 0 toRem(40) 0 0;
          // }
        }

        &:nth-child(2) {
          &::after {
            transition: all 0.5s ease 0.05s;
          }
          .wrap {
            transition: all 0.4s ease 0.05s;
          }
        }
        &:nth-child(3) {
          &::after {
            transition: all 0.5s ease 0.1s;
          }
          .wrap {
            transition: all 0.4s ease 0.1s;
          }
        }
        &:nth-child(4) {
          &::after {
            transition: all 0.5s ease 0.15s;
          }
          .wrap {
            transition: all 0.4s ease 0.15s;
          }
        }
        &:nth-child(5) {
          &::after {
            transition: all 0.5s ease 0.2s;
          }
          .wrap {
            transition: all 0.4s ease 0.2s;
          }
        }
        &:nth-child(6) {
          &::after {
            transition: all 0.5s ease 0.25s;
          }
          .wrap {
            transition: all 0.4s ease 0.25s;
          }
        }
        &:nth-child(7) {
          &::after {
            transition: all 0.5s ease 0.3s;
          }
          .wrap {
            transition: all 0.4s ease 0.3s;
          }
        }
        &:nth-child(8) {
          &::after {
            transition: all 0.5s ease 0.35s;
          }
          .wrap {
            transition: all 0.4s ease 0.35s;
          }
        }
        &:nth-child(9) {
          &::after {
            transition: all 0.5s ease 0.4s;
          }
          .wrap {
            transition: all 0.4s ease 0.4s;
          }
        }
      }

      &Cr {
        position: relative;
        overflow: hidden;
        padding: toRemMobile(4) 0 0;
        margin: toRemMobile(100) 0 0 toRemMobile(68);

        img {
          width: toRemMobile(65);
        }

        & > img {
          opacity: 0;
        }

        span {
          left: 0;
          color: #c4dbe0;
          position: absolute;
          transform: translate(0, 100%);
          transition: all 0.4s ease 0.3s;
        }

        // @media (max-width: 340px) {
        //   margin: 100px 0 0 40px;
        // }
      }
    }
  }
}
