@import '../../functions';

.footer {
  position: relative;
  z-index: 1;
  // margin: 200px 0 0;
  margin: auto 0 0;
  padding: 0 0 toRem(20);
  background: #010001;
  background-color: rgba($color: #010001, $alpha: 1);

  @media (max-width: 991px) {
    // margin: 100px 0 0;
    position: static;
    z-index: 0;
    padding: 0 0 toRemMobile(12);
  }

  &Top {
    position: relative;
    padding: toRem(22) 0;
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-end;
      padding: toRemMobile(12) 0;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(53, 58, 60);
      background: linear-gradient(
        90deg,
        rgba(53, 58, 60, 1) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgb(53, 58, 60);
      background: linear-gradient(
        90deg,
        rgba(53, 58, 60, 1) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &__logo {
      height: toRem(26);

      @media (max-width: 991px) {
        height: toRemMobile(19);
        display: none;
      }

      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    &__links {
      display: flex;
      margin: 0 0 0 auto;

      @media (max-width: 991px) {
        justify-content: flex-end;
        margin: 0;
      }

      &Item {
        cursor: pointer;
        color: #c4dbe0;
        font-family: Helvetica Now Display;
        font-size: toRem(18);
        line-height: 110%;
        letter-spacing: toRem(0.18);

        &:hover {
          text-decoration-line: underline;
        }

        &:not(:first-child) {
          margin: 0 0 0 toRem(40);

          @media (max-width: 991px) {
            margin: 0 0 0 toRemMobile(13);
          }
        }

        @media (max-width: 991px) {
          white-space: nowrap;
          margin: 0;
          font-size: toRemMobile(9);
          letter-spacing: toRem(0.09);
        }
      }
    }
  }
  &Logo {
    position: relative;
    padding: toRemMobile(12) 0;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(53, 58, 60);
      background: linear-gradient(
        90deg,
        rgba(53, 58, 60, 1) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    svg {
      width: toRemMobile(74);
      height: auto;
    }
  }
  &Text {
    padding: toRem(25) 0;
    color: rgba(206, 225, 229, 0.7);
    font-family: Suisse Intl;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: toRem(0.14);

    @media (max-width: 991px) {
      padding: toRemMobile(12) 0;
      font-size: toRemMobile(8);
      letter-spacing: toRemMobile(0.08);
    }
  }
  &Body {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: toRem(22) 0 toRem(30);

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(53, 58, 60);
      background: linear-gradient(
        90deg,
        rgba(53, 58, 60, 1) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    &__info {
      display: flex;
      flex-direction: column;

      @media (max-width: 991px) {
        width: 100%;
        padding: toRemMobile(12) 0;
        position: relative;
        align-items: flex-start;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgb(53, 58, 60);
          background: linear-gradient(
            90deg,
            rgba(53, 58, 60, 1) 30%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }

      &Title {
        white-space: nowrap;
        color: #c4dbe0;
        font-family: Helvetica Now Display;
        font-size: toRem(28);
        font-style: normal;
        font-weight: 400;
        line-height: 94%;
        letter-spacing: toRem(-0.28);
        margin: 0 0 toRem(10);

        @media (max-width: 991px) {
          font-size: toRemMobile(16);
          margin: 0 0 toRemMobile(5);
        }
      }

      &Link {
        display: block;
        color: #c4dbe0;
        font-family: Suisse Intl;
        font-size: toRem(14);
        line-height: 120%;
        letter-spacing: toRem(0.14);

        @media (max-width: 991px) {
          font-size: toRemMobile(9);
          letter-spacing: toRemMobile(0.08);
        }

        &:hover {
          text-decoration-line: underline;
        }

        &:not(:first-child) {
          margin: toRem(5) 0 0;

          @media (max-width: 991px) {
            margin: toRemMobile(2) 0 0;
          }
        }
      }
    }
    &__descr {
      flex: 0 0 toRem(534);
      color: rgba(196, 219, 224, 0.6);
      font-family: Suisse Intl;
      font-size: toRem(12);
      line-height: 120%;
      letter-spacing: toRem(0.12);

      @media (max-width: 991px) {
        margin: toRemMobile(15) 0 0;
        padding: toRemMobile(25) 0 0;
        border-top: toRemMobile(2) solid rgba(196, 219, 224, 0.6);
        flex: 0 0 auto;

        font-size: toRemMobile(8);
        line-height: 90%;
        letter-spacing: toRemMobile(0.08);
      }
    }

    &__ltd {
      color: #c4dbe0;
      font-family: Suisse Intl;
      font-size: toRem(14);
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: toRem(0.14);

      @media (max-width: 991px) {
        font-size: toRemMobile(9);
        letter-spacing: toRemMobile(0.09);
      }
    }

    &__privacy {
      cursor: pointer;
      color: #c4dbe0;
      font-family: Suisse Intl;
      font-size: toRem(14);
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: toRem(0.14);

      @media (max-width: 991px) {
        font-size: toRemMobile(9);
        letter-spacing: toRemMobile(0.09);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &Bottom {
    padding: toRem(12) 0 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      padding: toRemMobile(12) 0 0;
    }
  }
  &Links {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 991px) {
      justify-content: flex-start;
    }

    &__item {
      cursor: pointer;
      color: rgba(196, 219, 224, 0.6);
      font-family: Suisse Intl;
      font-size: toRem(10);
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: toRem(0.1);

      @media (max-width: 991px) {
        font-size: toRemMobile(8);
        line-height: 90%;
        letter-spacing: toRemMobile(0.08);
      }

      &:hover {
        text-decoration-line: underline;
      }

      &:not(:first-child) {
        margin: 0 0 0 toRem(50);

        @media (max-width: 991px) {
          margin: 0 0 0 toRemMobile(20);
        }
      }
    }
  }
}
