.content {
  position: relative;
  z-index: 1;

  &__video {
    position: absolute;
    pointer-events: none;
    z-index: -1;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:not(&.managers) {
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.managers {
      video {
        position: absolute;
        width: 160%;
        height: 100%;
        object-fit: contain;

        &:nth-child(1) {
          top: 0;
          object-position: top;
          right: 0;
        }
        &:nth-child(2) {
          bottom: 0;
          object-position: bottom;
        }
      }
    }
  }
}

canvas.hidden {
  opacity: 0;
}
