@font-face {
  font-family: 'Stylish';
  font-display: swap;
  src: url('../assets/fonts/Stylish.woff2') format('woff2'),
    url('../assets/fonts/Stylish.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI';
  font-display: swap;
  src: url('../assets/fonts/SegoeUI.woff2') format('woff2'),
    url('../assets/fonts/SegoeUI.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Staatliches';
  font-display: swap;
  src: url('../assets/fonts/StaatlichesRegular.woff2') format('woff2'),
    url('../assets/fonts/StaatlichesRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Suisse Intl';
  font-display: swap;
  src: url('../assets/fonts/SuisseIntl.woff2') format('woff2'),
    url('../assets/fonts/SuisseIntl.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display';
  font-display: swap;
  src: url('../assets/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display';
  font-display: swap;
  src: url('../assets/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display';
  font-display: swap;
  src: url('../assets/fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-display: swap;
  src: url('../assets/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueCyr-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
